<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <!-- banner -->
    <solutionBanner id="solutionBanner" ref="solutionBanner" :from="'vision'" />
    <!-- 二级导航 -->
    <navBar ref="navBar" :from="'Scheme'" />
    <!-- 办事现状 -->
    <div id="hyfx_div" class="fenxi-box">
      <h1 class="h1-title">办事现状</h1>
      <div class="fenxi conBox flex-between flex-align">
        <div class="imgs">
          <img class="img" src="@/assets/solution/cyyq/fenxi-js.png">
          <!-- <div class="color-block" /> -->
        </div>
        <div>
          <div class="text" style="margin-top:15px">
            由于区域内的办事居民或企业想要前往县级或市级政务服务中心办理业务需要花费较多时间在路途中和排队等候中，对于办事居民或企业来说不够便捷省时，而基层（乡镇、街道、村社等）便民服务中心的办事人员往往存在如下问题：
          </div>
          <div class="text" style="margin-top:15px">
            <div class="text-title">
              1、基层办事人员无权限、不会办、不会答
            </div>
            基层(乡镇、街道等)便民服务中心窗口人员存在“无权限、不会办、答不上”等堵点难点。
          </div>
          <div class="text" style="margin-top:15px">
            <div class="text-title">
              2、拿捏不准、暂未下延
            </div>
            基层政务服务单位大多只提供一些简单的事项，缺少多元化的服务内容和手段，基层窗口人员经常遇到“拿捏不准”、“暂未下延”的事项。
          </div>
          <div class="text" style="margin-top:15px">
            <div class="text-title">
              3、业务能力和咨询服务能力不强
            </div>
            基层窗口人员业务能力和咨询服务能力不强，不能满足群众的政务咨询，不能满足“有疑就问、有问必答、答必解惑”的目标。
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案能力全景 -->
    <div id="faqj_div" class="conBox nlfa">
      <h1 class="h1-title">解决方案能力全景</h1>
      <img class="img" src="@/assets/solution/cyyq/nlfa-js.png">
      <img class="img1" src="@/assets/solution/cyyq/jjfabg-zw.png">
    </div>
    <!-- 平台典型功能介绍 -->
    <carouselBanner id="ptgn_div" ref="carouselBanner" :from="'vision'" />
    <!-- 适用场景 -->
    <div id="sycj_div" class="sycj-div">
      <h1 class="h1-title">适用场景</h1>
      <sycj ref="sycj" class="conBox" :from="'vision'" />
    </div>
    <!-- 数字嘉园 美好生活 -->
    <footers ref="footers" />
  </div>
</template>

<script>
import solutionBanner from './components/solutionBanner.vue';
import sycj from './components/sycj.vue';
import footers from './components/footer.vue';
import carouselBanner from './components/carouselBanner.vue';
import navBar from './components/navBar.vue';
export default {
  name: 'Solution',
  components: { solutionBanner, sycj, footers, carouselBanner, navBar },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.fenxi-box {
  padding-bottom: 80px;
  background: #F9FBFF;
  .fenxi {
    .imgs,.img{
      position: relative;
      width: 594px;
      height: 380px;
      .color-block {
        position: absolute;
        bottom: -24px;
        left: -32px;
        width: 73px;
        height: 73px;
        background: #30a5ff;
      }
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
      padding-left: 50px;
      text-align: justify;
      .text-title {
        font-weight: bold;
      }
    }
  }
}
.nlfa {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  .img {
    width: 1229px;
    height: 563px;
    margin: auto;
  }
  .img1 {
    position: absolute;
    width: 603px;
    height: 506px;
    top: 250px;
    right: -181px;
  }
}
</style>

